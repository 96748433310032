//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getStarRatePlatformStarRate } from "@/api/province";
export default {
  data() {
    return {
      // 结束日期不能大于开始日期
      pickerOptions: {
        disabledDate: (time) => {
          let startTime = this.ruleForm.startTime;
          if (startTime) {
            // console.log(time,time.getTime());
            return time.getTime() < new Date(startTime).getTime();
          }
        },
      },
      // 开始日期不能小于结束日期
      startPickerOptions: {
        disabledDate: (time) => {
          let endTime = this.ruleForm.endTime;
          if (endTime) {
            return time.getTime() > new Date(endTime).getTime();
          }
        },
      },
      // startTime: "",
      // endTime: "",
      centerDialogVisible: false,
      options: [
        {
          value: "1",
          label: "通过",
        },
        {
          value: "2",
          label: "不通过",
        },
      ],
      ruleForm: {
        result: "1",
        rate: 3,
        opComment: "",
        reason: "",
        startTime: "",
        endTime: "",
      },
      // result: "1",
      // rate: 3,
      // reason: "",
      deptId: "",
      year: "",
      // opComment: "",
      rules: {
        result: [{ required: true, message: "请选择是否通过", trigger: "change" }],
        rate: [{ required: true, message: "请选择评分", trigger: "change" }],
        startTime: [
          {
            required: true,
            message: "请选择证书有效起始时间",
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择证书有效截至时间",
            trigger: "change",
          },
        ],
        opComment: [{ required: true, message: "请填写证书编号", trigger: "blur" }],
        reason: [{ required: true, message: "请填写拒绝原因", trigger: "blur" }],
      },
    };
  },
  methods: {
    close() {
      this.centerDialogVisible = false;
      this.ruleForm = {
        result: "1",
        rate: 3,
        opComment: "",
        reason: "",
        startTime: "",
        endTime: "",
      };
      // this.result = "1";
      // this.rate = 3;
      // this.reason = "";
      this.deptId = "";
      this.year = "";
      // this.starTime = "";
      // this.endTime = "";
    },
    sureCheck(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {};
          if (this.ruleForm.result == 1) {
            form = {
              result: this.ruleForm.result,
              level: this.ruleForm.rate,
              // reason: this.reason,
              deptId: this.deptId,
              opComment: this.ruleForm.opComment,
              year: this.year,
              startTime: this.ruleForm.startTime,
              endTime: this.ruleForm.endTime,
            };
          } else {
            form = {
              result: this.ruleForm.result,
              // level: this.rate,
              reason: this.ruleForm.reason,
              deptId: this.deptId,
              year: this.year,
              // startTime: this.ruleForm.startTime,
              // endTime: this.ruleForm.endTime,
            };
          }
          _getStarRatePlatformStarRate(form).then((res) => {
            // console.log(res)
            if (res.code == 1) {
              this.close();
              this.$emit("init");
              this.$message(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
