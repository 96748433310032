//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode } from '@/api/organizational'
import CheckStars from './CheckStars.vue'
//获取省市区三级联动数据接口
import {
  _getProvinceList,
  _getProvinceCityList,
  _getCityCountyList,
  _getSubmitUser,
  _getgetinfo,
  _download,
  _getUniqueName,
  _getStarRate,
  _getStarRateDownload,
  _getStarRatePlatformStarRateInfo,
  _getStarRatePlatformStarRateListByPage,
} from '@/api/province'
export default {
  data() {
    return {
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      contents: '星级评定详情',
      dialogVisible: false,
      textbtn: '审核',
      ssss: '',
      dataAdd: [],
      formInline: {
        name: '', //主体名称
        organType: '', //主体机构类型
        address: '', //详细地址
        contactName: '', //联系人姓名
        contactPhone: '', //联系人手机号
        email: '', //邮箱
        code: '', //社会信用代码
        regionCode: '', //市
        provinceid: '', //活动区域
        cityid: '', //省
        businessLicenseOssIds: [], //营业执照回显数组
        securityqualificationOssIds: [], //安防回显数组
      },
      suffix: '',
      ossIds: [], //提交的时候图片的ossid数组
      cityCountylist: [],
      provinceCitylist: [],
      provinceList: [],
      imgsList: [], // 文件上传列表
      newimgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      disab: false, //其他禁用状态
      disabstar: false, //星级评定禁用状态
      roletype: '', //角色

      fileList: [], //文件数组
      curFileList: [], //ossid存储数组
      form: {
        opComment: '', //附件备注
      },
      // shows: false, //星级评定是否显示
      uploads: false, //判断是都需要上传
      btns: false, //按钮是否显示
      tableDatass: [],
    }
  },
  components: {
    CheckStars,
  },
  created() {
    this.roletype = JSON.parse(localStorage.getItem('roleType'))
    this.getcode()
    //获取省的数据
    _getProvinceList({}).then((res) => {
      this.provinceList = res.data
    })
    console.log(this.$route.query)
    //星级评定回显数据
    // _getStarRatePlatformStarRateInfo
    _getStarRatePlatformStarRateListByPage(this.$route.query).then((resq) => {
      // console.log(resq)
      if (resq.code == 1) {
        this.tableDatass = resq.data.data
      }
    })
    // 回显数据
    _getgetinfo(this.$route.query).then((res) => {
      if (res.code == '1') {
        if (res.data == null || res.data.checkStatus == null) {
          this.disab = false
        }
        // 审核中
        else if (res.data.checkStatus == '0') {
          this.formInline = Object.assign(res.data)

          this.formInline.organType = res.data.organType
          this.disab = true
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
        } else if (res.data.checkStatus == '1') {
          this.formInline = Object.assign(res.data)
          this.disab = true
          // this.shows = true
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
        } else if (res.data.checkStatus == '2') {
          this.formInline = Object.assign(res.data)
          this.disab = false
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            (res) => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            (res) => {
              this.cityCountylist = res.data
            },
          )
        }
        console.log(this.formInline, this)
      }
    })
  },
  methods: {
    init() {
      _getStarRatePlatformStarRateListByPage(this.$route.query).then((resq) => {
        console.log(resq)
        if (resq.code == 1) {
          this.tableDatass = resq.data.data
        }
      })
    },
    // 展开行
    clickTable(row, index, e) {
      console.log(row)
      this.$refs.refTable.toggleRowExpansion(row)
    },
    // disableButton() {
    //   this.disabstar = true
    // },
    openCheckStars(e) {
      console.log(this.$refs.CheckStars)
      this.$refs.CheckStars.centerDialogVisible = true
      this.$refs.CheckStars.year = e.year
      this.$refs.CheckStars.deptId = this.$route.query.deptId
    },
    goBack() {
      this.$router.push('/main/ptstarrating/starratinlist')
    },
    handleClickLook(scope) {
      //点击查看
      console.log(scope)
      // console.log(scope, scope.response.data.ossId)
      window.open(
        // f631bbe9ebc249eaa6362aadd305e266
        `/ananops/api/v1/pub/file/download?ossId=${scope.id}&type=pc&opration=inline`,
        //  `http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId= f631bbe9ebc249eaa6362aadd305e266&type=pc`,
        // 'http://130.10.7.107:9001/ananops/%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E5%88%97%E8%A1%A8.xls?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minioadmin%2F20220222%2F%2Fs3%2Faws4_request&X-Amz-Date=20220222T033734Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=398ae9530e00c0e9c55bc0ae36c85c01a023af9fc67206eb94873ef92bd6a5af'
      )
    },
    download(data) {
      console.log(data.id)
      const a = document.createElement('a')
      // f631bbe9ebc249eaa6362aadd305e266   word
      a.href = `/ananops/api/v1/pub/file/download?ossId=${data.id}&type=pc&opration=download`
      a.click()
      // _getStarRateDownload({
      //   ossId: 'ad8876cb136f44e48f38647a0f5b1c76',
      //   type: 'pc',
      // }).then((res) => {
      //   console.log(res)
      //   // const link = document.createElement('a')
      //   const blob = new Blob([res], { type: 'application/pdf' })

      //   const a = document.createElement('a')
      //   // 利用URL.createObjectURL()方法为a元素生成blob URL
      //   a.href = URL.createObjectURL(blob)
      //   // 设置文件名，目前只有Chrome和FireFox支持此属性
      //   // const filename = window.vm.$cookies.get('filename')
      //   // const filename = '维修工单列表'
      //   // a.download = filename
      //   a.click()
      // })
    },
    downloads(data) {
      // console.log(data.enclosures)
      data.enclosures.forEach((item, i) => {
        setTimeout(() => {
          const a = document.createElement('a')
          // f631bbe9ebc249eaa6362aadd305e266   word
          a.href = `/ananops/api/v1/pub/file/download?ossId=${item.id}&type=pc&opration=download`
          // a.target='_blank'
          // console.log(item, a)
          a.click()
          // if (i == this.multipleSelection.length - 1) {
          //   this.disableDownload = false
          // }
        }, 500 * i)
      })
    },
    wordDetail() {
      // 微软提供的方法
      // window.open('http://api.bjwh.org.cn/uploads/20220130/b47bbfc8d7dcc907ffc25b80f8273a1c.PDF')
      //  window.open('https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc')
      //  xmlHttp.EditDocument("http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId=f631bbe9ebc249eaa6362aadd305e266&type=pc");
      // window.open('https://view.officeapps.live.com/op/view.aspx?src=http://mczaiyun.top/ht/3.docx')
      // window.open('http://130.10.7.48:8002/ananops/api/v1/pri/supervision/exportreport')
    },
    changeProvince(e) {
      //获取市的数据
      _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
        (res) => {
          this.provinceCitylist = res.data
        },
      )
    },
    changeProvinceCity(e) {
      //获取区的数据
      _getCityCountyList({ parent_id: this.formInline.cityid }).then((res) => {
        this.cityCountylist = res.data
      })
    },
    // 审核按钮
    submitUser() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          _getSubmitUser(this.formInline).then((res) => {
            if (res.code == 1) {
              this.disab = true
              this.textbtn = '审核中'
              this.$message('提交审核成功,请您耐心等待')
            }
          })
        }
      })
    },
    // 审核按钮
    submitUsers(e) {
      console.log('点击评级')
      // this.centerDialogVisible = true
      this.openCheckStars(e)
    },
    // 失焦事件
    blurs() {
      _getUniqueName({ name: this.formInline.name }).then((res) => {
        if (res.code == '1') {
          this.suffix = 'el-icon-success'
        } else {
          this.suffix = 'el-icon-error'
        }
      })
    },
    getcode() {
      let from = {
        para: 'jglx',
      }
      _getcode(from).then((res) => {
        this.dataAdd = res.data
      })
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 附件成功返回的数据
    successFile(response, file, fileList) {
      // this.formInline.businessLicenseOssIds.push(response.data.ossId)
      this.fileList.push(file)
      this.curFileList.push(response.data.ossId)
      console.log(response, file, fileList, this.fileList)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`,
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    beforeUpload(file) {
      console.log(file)
    },
    handleClick(scope) {
      //点击查看
      console.log(scope, scope.response.data.ossId)
      window.open(
        `/ananops/api/v1/pub/file/download?ossId=${scope.response.data.ossId}&type=pc&opration=inline`,
        // 'http://130.10.7.107:9001/ananops/%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E5%88%97%E8%A1%A8.xls?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minioadmin%2F20220222%2F%2Fs3%2Faws4_request&X-Amz-Date=20220222T033734Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=398ae9530e00c0e9c55bc0ae36c85c01a023af9fc67206eb94873ef92bd6a5af'
      )
    },
    deleteFile(scope) {
      this.fileList = this.fileList.filter((item) => {
        return item.response.data.ossId != scope.response.data.ossId
      })
      this.curFileList = this.curFileList.filter((item) => {
        return item != scope.response.data.ossId
      })
    },
    changeStar() {
      this.disabstar = false
      this.uploads = true
      this.textbtn = '审核'
    },
  },
}
